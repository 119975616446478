@font-face {
  font-family: 'GoodTimes';
  src: url('./assets/fonts/GoodTimes.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GoodTimesHeavy';
  src: url('./assets/fonts/GoodTimesHeavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/MontserratSemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('./assets/fonts/Montserrat.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('./assets/fonts/Montserrat.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  color: #fff;
  font-family: GoodTimes;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  background-color: #000000;
  line-height: 1.25;
  user-select: none;
  -webkit-user-drag: none;
}
ol,
ul,
dl {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

pre,
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

body {
  --status-bar-height: constant(safe-area-inset-top, 0);
  --status-bar-height: env(safe-area-inset-top, 0);
  --bottom-bar-height: constant(safe-area-inset-bottom, 0);
  --bottom-bar-height: env(safe-area-inset-bottom, 0);
}

a:hover,
button:hover {
  cursor: pointer;
}

.overlay::before,
.overlay::after {
  display: none;
}

.marquee + .marquee {
  margin-left: 50px;
}

:root {
  --animate-duration: 1.5s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animate__animated.animate__faster {
  -webkit-animation-duration: calc(1s / 2);
  animation-duration: calc(1s / 2);
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}
.animate__animated.animate__fast {
  -webkit-animation-duration: calc(1s * 0.8);
  animation-duration: calc(1s * 0.8);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.8);
  animation-duration: calc(var(--animate-duration) * 0.8);
}
.animate__animated.animate__slowestt {
  -webkit-animation-duration: calc(1s * 10);
  animation-duration: calc(1s * 10);
  -webkit-animation-duration: calc(var(--animate-duration) * 10);
  animation-duration: calc(var(--animate-duration) * 10);
}

.animate__animated.animate__slower {
  -webkit-animation-duration: calc(1s * 7);
  animation-duration: calc(1s * 7);
  -webkit-animation-duration: calc(var(--animate-duration) * 7);
  animation-duration: calc(var(--animate-duration) * 7);
}

.animate__animated.animate__slowest {
  -webkit-animation-duration: calc(1s * 8);
  animation-duration: calc(1s * 8);
  -webkit-animation-duration: calc(var(--animate-duration) * 8);
  animation-duration: calc(var(--animate-duration) * 8);
}

[data-aos][data-aos][data-aos-duration='400'],
body[data-aos-duration='400'] [data-aos] {
  transition-duration: 0.7s;
  transition-delay: 0.05s;
}

@-webkit-keyframes shakeY {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
}

@keyframes shakeY {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  -webkit-animation-name: shakeY;
  animation-name: shakeY;
}
